import React, { ReactElement, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Button,
  createStyles,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  StyleRules,
  Tab,
  Tabs,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import {
  CancelCompanyPublishProjectDialog,
  ConsultantBreakProjectDialog,
} from '../../components/Dialog';
import DialogTitle from '../../components/DialogTitle';
import CheckBillsView from './checkBillsView';
import PublishProjectView from './publishProjectView';

const styles = (): StyleRules =>
  createStyles({
    contentPadding: {
      minHeight: '80vh',
      padding: '20px',
    },
    childrenItem: {
      width: '100%',
      borderRadius: '3px',
      // minHeight: '80vh',
      paddingBottom: '48px',
    },
    buttonMargin: {
      margin: '0 0.25rem',
    },
    DialogWidth: {
      minWidth: '850px',
      border: '20px solid red',
    },
  });

const projectListView = ({ classes }: Props): ReactElement => {
  const [value, setValue] = useState(0);

  const [open, setOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  editOpen;
  const [breakDialog, setBreakDialog] = useState(false);
  const [checkBillsDialog, setCheckBillsDialog] = useState(false);
  const [isCancelDialog, setIsCancelDialog] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const checkBillsHandleClose = () => {
    setCheckBillsDialog(false);
  };

  const checkBillsHandleOpen = () => {
    setCheckBillsDialog(true);
  };
  const breakDialogOpen = () => {
    setBreakDialog(true);
  };

  const handleEditOpen = () => {
    setEditOpen(true);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: '项目名称',
      // editable: true,
      flex: 1,
    },
    {
      field: 'age',
      headerName: '结算金额',
      type: 'number',
      // editable: true,
      flex: 1,
    },
    {
      field: 'id',
      headerName: '操作',
      type: 'number',
      width: 400,
      sortable: false,
      renderCell: () => (
        <>
          <Button
            type="button"
            variant="contained"
            color="primary"
            className={classes.buttonMargin}
            onClick={
              // handleShowRowToDialog(rowData.row)
              () => {
                setOpen(true);
              }
            }
          >
            查看
          </Button>
        </>
      ),
    },
  ];

  const rows = [
    { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
    { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
    { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
    { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
    { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
    { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
    { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
    { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
    { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
  ];
  return (
    <>
      <Helmet title="进行中的项目"></Helmet>
      <Grid container className={classes.contentPadding} justifyContent="space-between">
        <Paper square className={classes.childrenItem}>
          <Tabs
            variant="fullWidth"
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            <Tab label="进行中" />
            <Tab label="已完成" />
          </Tabs>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            disableSelectionOnClick
            className={classes.DataGridMargin}
          />
        </Paper>
      </Grid>
      <Dialog onClose={handleClose} open={open} maxWidth="lg">
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          项目信息
        </DialogTitle>
        <DialogContent dividers>
          <PublishProjectView
            failShow={true}
            projectStatus={value}
            onClose={handleClose}
            editOpen={handleEditOpen}
            breakDialogOpen={breakDialogOpen}
            checkBillsHandleOpen={checkBillsHandleOpen}
          ></PublishProjectView>
        </DialogContent>
      </Dialog>

      <Dialog onClose={checkBillsHandleClose} open={checkBillsDialog} maxWidth="lg">
        <DialogTitle id="customized-dialog-title" onClose={checkBillsHandleClose}>
          账单
        </DialogTitle>
        <DialogContent dividers>
          <CheckBillsView></CheckBillsView>
        </DialogContent>
      </Dialog>

      <CancelCompanyPublishProjectDialog
        isOpen={isCancelDialog}
        handleEdit={() => {
          setIsCancelDialog(false);
        }}
        handleDialogClose={() => {
          setIsCancelDialog(false);
        }}
      ></CancelCompanyPublishProjectDialog>
      <ConsultantBreakProjectDialog
        isOpen={breakDialog}
        handleEdit={() => {
          setBreakDialog(false);
        }}
        handleDialogClose={() => {
          setBreakDialog(false);
        }}
      ></ConsultantBreakProjectDialog>
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
}

export default withStyles(styles)(projectListView);
