import React from 'react';

import Layout from '../../layout/ConsultantLayout';
import SettlementProjectListView from '../../views/consultant/settlementProjectListView';
const SettlementProject = (): React.ReactElement => {
  return (
    <Layout>
      <SettlementProjectListView></SettlementProjectListView>
    </Layout>
  );
};

export default SettlementProject;
